<template>
  <div class="about"></div>
</template>

<script>
export default {
  name: 'About',
  created: function () {
    // location.replace('/')
    this.$router.push('/')
  },
}
</script>
